<template>
  <div class="account-login full-page">
    <div class="login flex">
      <div class="form-wrapper">
        <a-form name="login" :form="form" class="form">
          <div class="flex login-text">登录</div>
          <div class="sub-login-text">使用{{ ["", "奔腾苑账号登录", "DMS账号密码登录"][identityType] }}</div>

          <div class="input-wrapper bottom-line">
            <img src="./img/user.png" alt="" class="input-wrapper-img">
            <a-form-item label="" name="userName">
              <a-input :allow-clear="true" :placeholder="['', '用户名', '身份证号'][identityType]"
                       v-decorator="['userName', { rules: [{ required: true, message: `请输入${['', '用户名', '身份证号'][identityType]}` }] }]"
                       class="login-input" @keypress.enter="onSubmit"></a-input>
            </a-form-item>
          </div>

          <div class="input-wrapper bottom-line">
            <img src="./img/lock.png" alt="" class="input-wrapper-img">
            <a-form-item label="" name="password">
              <a-input :allow-clear="true" placeholder="密码" type="password"
                       v-decorator="['password', { rules: [{ required: true, message: '请输入密码' }] }]"
                       class="login-input" @keypress.enter="onSubmit"></a-input>
            </a-form-item>
          </div>

          <div class="input-wrapper bottom-line login-captcha-wrapper">
            <img src="./img/shield.png" alt="" class="input-wrapper-img">
            <a-form-item label="" name="captcha">
              <a-input :allow-clear="true" placeholder="验证码" type="captcha"
                       v-decorator="['captcha', { rules: [{ required: true, message: '请输入验证码' }] }]"
                       class="login-input" @keypress.enter="onSubmit"></a-input>
            </a-form-item>
            <img :src="'/admin/gos/Verify?timestamp=' + timestamp" alt="" class="captcha" @click="updateCaptcha">
          </div>

          <div class="input-wrapper reset-password-wrapper">
            <a-button v-show="identityType === 1" type="link" size="small" @click="resetPassword">忘记密码</a-button>
          </div>

          <div class="login-btn-wrapper">
            <a-form-item>
              <a-button type="primary" size="large" html-type="button" block :loading="loading"
                        class="login-btn" @click="onSubmit">
                <span>登录</span>
              </a-button>
            </a-form-item>

            <div class="or-line">或</div>

            <a-form-item>
              <a-button type="default" size="large" html-type="button" block :loading="loading"
                        class="login-btn other-login" @click="switchType(identityType === 2 ? 1 : 2)">
                <span v-if="identityType === 2">奔腾苑账号登录</span>
                <span v-else>DMS账号密码登录</span>
              </a-button>
            </a-form-item>
          </div>
        </a-form>
      </div>

      <img src="./img/brand.png" alt="" class="login-logo">
    </div>

    <a href="https://beian.miit.gov.cn/" class="beian">粤ICP备16046228号</a>
  </div>
</template>

<script>
import { isProd } from '@/Config';
import { login } from '@/account/api';

export default {
  name: 'Login',
  data() {
    return {
      loading: false,
      timestamp: new Date().getTime(),

      form: this.$form.createForm(this, { name: 'coordinated' }),

      identityType: 2,
      userName: '',
      password: '',
    };
  },
  methods: {
    onSubmit() {
      this.form.validateFields((err, values) => {
        if (err) {
          // this.$message.info({ content: '请填写必填项' });
          return this.loading = false;
        }

        const params = {
          login_type: "pc",
          username: values.userName,
          password: values.password,
          code: values.captcha,
          callback: this.$route.query.callback_url || '',
          identity_type: this.identityType,
        };

        this.login(params);
      });
    },
    async login(params) {
      if (this.loading) { return Promise.reject(new Error('Loading')); }
      this.loading = true;

      const data = await login(params).catch(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.loading = false;
        return this.$message.error(data?.message || '登录失败');
      }
      localStorage.setItem('token', data?.data?.user_token || '');
      sessionStorage.setItem('token', data?.data?.user_token || '');
      this.$message.success(data?.message || '登录成功');
      // this.$nextTick(() => {
      //   this.$redirect(data?.url || '');
      // });
      const path = isProd ? decodeURIComponent(data?.data?.url) : `/#/transition?token=${data?.data?.user_token}`;
      setTimeout(() => {
        this.$redirect(path || '');
        // this.loading = false;
      }, 1000);
      return Promise.resolve();
    },

    resetPassword() {
      this.$router.push('/account/reset');
    },

    switchType(type) {
      this.identityType = type || 2;
    },

    // 更新图形验证码
    updateCaptcha() {
      this.timestamp = new Date().getTime();
    }
  },
};
</script>

<style scoped lang="scss">
.account-login {
  overflow: auto;
  position: relative;
  background-color: #FFFFFF;

  .login {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1200px;
    height: 670px;
  }

  .form-wrapper {
    position: relative;
    width: 50%;
    height: 100%;
    background: #F1F3FC;
  }

  .form {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }

  .input-wrapper {
    position: relative;
    width: 60%;
    height: 72px;
    margin: 0 auto 5px;
    padding-left: 40px;
  }

  .reset-password-wrapper {
    //margin-top: 20px;
    padding: 0;
  }

  .captcha-wrapper {
    padding-right: 100px;
  }

  .captcha-btn {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .bottom-line {
    border-bottom: 1px solid #DDDDDD;
  }

  .input-wrapper-img {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 23px;
  }

  .login-input {
    height: 50px;
    border: none !important;
    border-radius: 0 !important;
    background-color: transparent !important;
    box-shadow: none !important;
    outline: none !important;
  }

  .login-input ::v-deep .ant-input {
    height: 100%;
    padding-top: 25px;
    border: none !important;
    background-color: transparent;
    box-shadow: none !important;
    outline: none !important;
  }

  ::v-deep .ant-form-explain {
    transform: translateY(-3px);
  }

  ::v-deep .ant-input-clear-icon {
    transform: translateY(12px);
  }

  .login-logo {
    width: 50%;
  }

  .login-text {
    font-size: 36px;
    font-weight: 500;
    color: #5075FF;
  }

  .sub-login-text {
    height: 17px;
    margin-top: 12px;
    margin-bottom: 25px;
    text-align: center;
    font-size: 14px;
    font-weight: 300;
    color: #666666;
  }

  .login-btn-wrapper {
    position: relative;
    width: 60%;
    margin: 0 auto 5px;
  }

  .login-btn {
    height: 42px;
    margin: 0;
  }

  .or-line {
    display: flex;
    height: 30px;
    margin: 28px 0;
    font-size: 16px;
    font-family: PingFangSC-Regular-, PingFangSC-Regular, sans-serif;
    font-weight: normal;
    color: #9A9DA4;

    &::before, &::after {
      flex-grow: 1;
      display: block;
      content: "";
      width: 1px;
      height: 15px;
      border-bottom: 1px solid #DDDDDD;
    }

    &::before {
      margin-right: 32px;
    }

    &::after {
      margin-left: 32px;
    }
  }

  .other-login {
    border-color: #6388FF;
    color: #6388FF;
  }

  .hint-msg {
    width: 60%;
    margin: 0 auto 5px;
    font-size: 12px;
    font-weight: 500;
    color: #83868E;
  }

  .login-captcha-wrapper {
    padding-right: 210px;
  }

  .captcha {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 200px;
    height: 62px;
    object-fit: contain;
    cursor: pointer;
  }
}

.beian {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}
</style>
